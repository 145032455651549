<template>
  <div>
    <span @click="toolsDrawer = !toolsDrawer">
      <slot></slot>
    </span>
    <v-navigation-drawer
      width="400px"
      v-model="toolsDrawer"
      temporary
      :persistent="!isUploading"
      right
      app
    >
      <div class="tool-box">
        <h2>Attach File</h2>
        <div class="tool-box__header">
          <div class="text-hint">
            <v-icon color="#292D32" class="px-0">mdi-bell-outline</v-icon
            ><span>Attachment</span>
          </div>
          <p>
            Use this tool to upload a file and add it to this invoice. The file
            will be combined with the invoice into one document.
          </p>
        </div>

        <div class="tool-box__content">
          <div>
            <v-label class="fieldLabel">Select Attachment</v-label>
            <v-select
              v-model="attachement"
              :items="attachementOptions"
              :loading="attachementOptions.length === 0"
              item-text="label"
              :disabled="uploadedFile !== null"
              item-value="hypn_id"
              height="54px"
              placeholder="or select New"
              outlined
              hide-details="auto"
            >
              <template v-slot:item="data">
                <div
                  v-if="data.item.label == 'New Document'"
                  class="font-weight-bold blue--text text--darken-3"
                >
                  {{ data.item.label }}
                </div>
                <div v-else>
                  {{ data.item.label }}
                </div>
              </template>
            </v-select>
          </div>

          <div
            v-if="attachement === 'new-document'"
            class="attachement-file-input"
          >
            <label class="attachement-file-input__label" for="upload-attachment"
              >Upload attachment
              <div class="attachement-file-input__input-wrapper">
                <div class="input-prefix"></div>
                <div class="file-input-target">
                  {{ attachementFile.name || "+ tap to upload" }}
                </div>
              </div>
            </label>
            <input
              id="upload-attachment"
              type="file"
              @change="handleUpload"
              accept="application/pdf"
              style="display: none"
            />
            <div class="attachement-file-input__description-text">PDF</div>
          </div>

          <div
            v-if="
              uploadedFile || (attachement !== 'new-document' && attachement)
            "
            class="upload-status"
          >
            <span v-if="attachement == 'new-document'">
              <v-icon>mdi-information-slab-circle-outline</v-icon> Upload
              successful</span
            >
            <span v-else>
              <v-icon>mdi-information-slab-circle-outline</v-icon> Merge
              document
            </span>

            <v-btn
              color="primary"
              @click="mergeDocument"
              :loading="isMerging"
              outlined
            >
              <span class="merge-content">
                <v-icon>mdi-check-decagram-outline</v-icon>

                {{ mergedObject ? "Merged" : "Merge" }}
              </span>
            </v-btn>

            <div v-if="mergedObject">
              <a :href="mergedObject.merge_url" target="_blank"
                >Download Merged Document</a
              >
            </div>
          </div>
        </div>
        <div class="tool-box__action">
          <v-btn
            large
            class="pa-2"
            elevation="0"
            @click="toolsDrawer = false"
            :disabled="isUploading"
            color="f4f5f6"
          >
            <v-icon class="px-0">mdi-close</v-icon>
            Close
          </v-btn>
          <v-btn
            large
            class="pa-2"
            elevation="0"
            :disabled="attachement !== 'new-document' || uploadedFile !== null"
            @click="uploadDocument"
            :loading="isUploading"
            color="primary"
          >
            <v-icon color="#fff" class="px-0">mdi-chevron-right</v-icon> Upload
          </v-btn>
        </div>
      </div>
      -
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    itemHypnID: {
      type: String,
      required: true,
    },
    entityType: String,
    dispatchURI: String,
  },
  data() {
    return {
      toolsDrawer: false,
      attachement: null,
      attachementFile: {
        name: "",
        size: "",
        type: "",
        file: null,
      },
      attachementOptions: [],
      isUploading: false,
      isMerging: false,
      uploadedFile: null,
      mergedObject: null,
    };
  },
  computed: {
    attachementPayload() {
      return {
        entity: this.entityType,
        entity_id: this.itemHypnID,
        file: this.attachementFile,
      };
    },
  },
  methods: {
    ...mapActions({ showToast: "ui/showToast" }),

    async getDocuments() {
      const docs = await this.$store.dispatch(
        `${this.dispatchURI}/fetchDocuments`
      );

      this.attachementOptions = docs.map((item) => {
        return {
          ...item,
          ...(!item.label && { label: item.entity + "-" + item.hypn_id }),
        };
      });

      this.attachementOptions.unshift({
        label: "New Document",
        hypn_id: "new-document",
      });
    },
    convertToMB(size) {
      return Math.round((size / 1024 / 1024) * 100) / 100;
    },

    handleUpload(e) {
      const files = e.target.files;

      if (files) {
        this.attachementFile.name = files[0].name;
        this.attachementFile.size = this.convertToMB(files[0].size);
        this.attachementFile.type = files[0].type;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        this.attachementFile.file = e.target.result;
      };

      if (e.target.files.length > 0) {
        reader.readAsDataURL(e.target.files[0]);
      }
    },

    async uploadDocument() {
      if (this.isUploading) return;
      try {
        this.isUploading = true;
        this.uploadedFile = await this.$store.dispatch(
          `${this.dispatchURI}/addDocument`,
          this.attachementPayload
        );
      } catch (error) {
        if (process.env.NODE_ENV === "development") {
          console.error(error);
        }

        this.attachementFile = {
          name: "",
          size: "",
          type: "",
          file: null,
        };

        this.attachement = null;
      } finally {
        this.isUploading = false;
      }
    },

    async mergeDocument() {
      if (this.isMerging || this.mergedObject) return;
      const file = {
        entity:
          this.uploadedFile?.entity ??
          this.attachementOptions.find(
            (item) => item.hypn_id === this.attachement
          ).entity,
        entity_id: this.itemHypnID,
        merge:
          this.uploadedFile?.hypn_id ??
          this.attachementOptions.find(
            (item) => item.hypn_id === this.attachement
          ).hypn_id,
      };

      try {
        this.isMerging = true;
        this.mergedObject = await this.$store.dispatch(
          `${this.dispatchURI}/mergeDocument`,
          file
        );

        this.showToast({
          sclass: "success",
          show: true,
          message: "Attachment merged successfully.",
          timeout: 3000,
        });
      } catch (error) {
        this.showToast({
          sclass: "error",
          show: true,
          message: "An error occurred while merging the document.",
          timeout: 3000,
        });
      } finally {
        this.isMerging = false;
      }
    },
  },
  watch: {
    toolsDrawer(val) {
      if (!val) {
        this.attachementFile = {
          name: "",
          size: "",
          type: "",
          file: null,
        };
        this.attachement = null;
        this.uploadedFile = null;
        this.mergedObject = null;
      } else {
        this.getDocuments();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tool-box {
  padding: 20px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  h2 {
    color: var(--hyphen-Blue, #19283d);
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 175% */
  }

  &__header {
    padding: 20px;
    margin: 20px 0px;
    border-radius: 4px;
    background: #f8f9fa;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.03);

    .text-hint {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;

      & > span:nth-child(2) {
        color: #000;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        margin-bottom: 3px;
      }
    }

    p {
      color: var(--body-text, rgba(25, 40, 61, 0.8));
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* 183.333% */
    }
  }

  &__content {
    overflow: auto;
    flex: 1;
    gap: 30px;
    display: flex;
    flex-direction: column;
    padding: 10px;

    .upload-status {
      background-color: #f6fdfc;
      padding: 20px;

      span {
        color: #000000;
        display: flex;
        align-items: center;
        gap: 10px;
        font-family: "Inter";
        font-size: 13px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0px;

        svg {
          color: #000000;
        }
      }

      button {
        margin-top: 20px;
        padding: 20px;
        border-radius: 3px;
        border: 1px solid #525e6e;
        background-color: #fff;

        .merge-content {
          font-size: 15px;
          color: #19283dcc;
          display: inline-flex;
          align-items: center;
          gap: 10px;
          text-transform: capitalize;

          svg {
            color: #292d32;
          }
        }
      }

      a {
        font-family: "Inter";
        font-size: 12px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0px;
        color: #5b67ba;
        display: inline-block;
        margin-top: 20px;
      }
    }
  }

  &__content::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
  }

  &__content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }

  &__content::-webkit-scrollbar-thumb {
    background-color: var(--hyphen-Blue, #19283d);
    border-radius: 10px;
  }

  &__action {
    margin-top: 50px;
    box-sizing: border-box;
    display: flex;
    gap: 20px;
    padding: 10px;
    margin-bottom: 40px;
    z-index: 99;

    button {
      flex: 1;

      padding: 15px 30px;
      border-radius: 4px;
      border: none;

      font-size: 16px;
      font-weight: 500;
      cursor: pointer;

      &.cancel {
        border-radius: 4px;
        background: var(--secondary-button, #f4f5f6);
      }

      &.apply {
        border-radius: 4px;
        color: #fff;
        background: var(--hyphen-Blue, #19283d);
      }
    }
  }

  /* Add other scrollbar styles for different browsers if needed */

  label {
    font-family: "inter";
    font-size: 14px;
    font-weight: 500;
    color: rgba(25, 40, 61, 0.8);
  }

  .attachement-file-input {
    box-sizing: border-box;
    font-family: "Inter";
    box-sizing: border-box;

    .hyphen-component-error {
      color: #ff0000;
    }

    &__label {
      color: rgba(25, 40, 61, 0.8);
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      font-weight: 500;
      padding-bottom: 10px;
    }

    &__input-wrapper {
      margin-top: 6px;
      display: flex;
      align-items: center;
      border: 1.8px dashed #d9dee1;
      border-radius: 5px;
      padding: 30px 0px;
      cursor: pointer;
      background-color: transparent;
      gap: 3px;
      box-sizing: border-box;
      background-color: #f6f8f8;

      .input-prefix {
        margin-left: 8px;
      }

      .file-input-target {
        box-sizing: border-box;
        min-width: auto;
        outline-style: none;
        border-radius: inherit;
        background-color: inherit;
        display: flex;
        align-items: center;
        cursor: pointer;

        color: #666;
        font-weight: 400;
        font-size: 15px;
      }

      .input-suffix {
        margin-right: 8px;
      }
    }

    &__description-text {
      margin-top: 6px;
      font-size: 12px;
      color: #797e86;
    }
  }
}
</style>
